import { graphql, useStaticQuery } from 'gatsby'
import formatNodes from '../utils/formatNodes'

const useCareersPage = () => {
  const nodes = useStaticQuery(
    graphql`
      fragment FluidImage on File {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      query {
        careersBanner: file(relativePath: { eq: "careers_top.jpg" }) {
          ...FluidImage
        }
        careerValue1: file(relativePath: { eq: "careers_value_01_1.jpg" }) {
          ...FluidImage
        }
        careerValue2: file(relativePath: { eq: "careers_value_01_2.jpeg" }) {
          ...FluidImage
        }
        careerValue3: file(relativePath: { eq: "careers_value_03.jpg" }) {
          ...FluidImage
        }
      }
    `
  )
  return formatNodes(nodes)
}

export default useCareersPage
